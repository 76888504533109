@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');


/* GENERAL CSS RULES */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 62.5%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
a {
	text-decoration: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* APP.JS */
.App {
	background-color: rgb(251, 251, 230);
	padding: 5rem 5rem .5rem 5rem;
}
/* HEADER SECTION */
.header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 3rem;
	margin-bottom: 3rem;
	height: auto;
}
header > h1 {
	font-family: 'Roboto Mono', monospace;
	font-size: 3rem;
	font-weight: 400;
	color: #787676;
	margin-bottom: 3rem;
}
.type-animation {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 2.5rem;
	color: rgb(109, 183, 252);
	width: 75%;
	margin-bottom: 20vh;
}
/* COLLAPSIBLE INFORMATION SECTION */
.collapse__element--container {
	height: 60vh;
}
.collapse__btn {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	background-color: #757474;
	font-family: 'Roboto Mono', monospace;
	font-size: 3rem;
	font-weight: 100;
	color: #fff;
	border-radius: .5rem;
	width: auto;
	height: auto;
	border: none;
	outline: none;
	box-shadow: none;
	transition: .2s all ease-in-out;
	padding: 2rem;
}
.collapse__btn:hover {
	background-color: rgb(109, 183, 252);
	transform: scale(1.01);
	text-transform: uppercase;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 2rem;
}
.collapse__btn:focus {
	background-color: #757474;
	transform: scale(1.01);
	text-transform: uppercase;
	border: none;
	outline: none;
	box-shadow: none;
	padding: 2rem;
}
.fade-wrapper {
	display: block;
}
.fade-element {
	min-height: min-content;
}
#fade-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
#fade-text > h3 {
	font-size: 3rem;
	font-family: 'Roboto Mono', monospace;
	color: rgb(109, 183, 252);
	height: auto;
	margin: 3rem;
}
#fade-text > a {
	font-size: 1.5rem;
	font-family: 'Roboto Mono', monospace;
	color: rgb(109, 183, 252);
	text-align: center;
	margin: .5rem;
	text-decoration: none;
}
#fade-text > a:hover {
	text-transform: none;
	cursor: pointer;
}
/* PROJECTS SECTION */
.projects__header {
	display: flex;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: auto;
	margin-top: 20vh;
}
.projects__header--h1 {
	color: #787676;
}
p, h1, h5, a {
	font-family: 'Roboto Mono', monospace;
}
h1 {
	font-size: 2rem;
}
/* .project__container {
	margin-bottom: 5rem;
} */
.project__wrapper {
	background-color: rgb(208, 239, 239);
	border-radius: .5rem;
	-webkit-box-shadow: 9px 8px 14px 3px rgba(0,0,0,0.28); 
	box-shadow: 9px 8px 14px 3px rgba(0,0,0,0.28);
}
.project__img {
	max-width: 90%;
	max-height: auto;
}
.project__info--header, 
.project__info--paragraph {
	color: #464545;
}
.project__info--h5 {
	color: #787676;
	font-weight: 700;
	font-size: 1rem;
	font-style: italic;
}
.project__info--arrow {
	height: 1rem;
	width: 1rem;
	color: #464545;
	margin-right: 1.5rem;
	animation:rotate 3s infinite linear;
	animation-fill-mode: both;
}
.project__info--stars {
	color: rgb(109, 183, 252);
	font-size: 1.3rem;
	margin: .5rem;
}
.project__info--ul > li {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 1rem 0;
	color: #787676;
}
.project__info--link-container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	background-color: rgb(109, 183, 252);
	border-radius: .5rem;
	width: 9rem;
	height: 4rem;
	margin-top: 2rem;
	transition: .3s all ease-in-out;
}
.project__info--link-container > a {
	text-decoration: none;
	color: white;
	transition: .3s all ease-in-out;
	cursor: pointer;
}
.project__info--link-container:hover {
	background-color: #757474;
	transform: scale(1.1);
	text-transform: uppercase;
}
.project__info--link-container > a:hover {
	text-decoration: none;
	text-transform: uppercase;
	color: white;
	font-weight: 900;
}
/* TECH STACKS */
/* Container for whole section */
.tech-stacks__container {
	padding: 5rem 2rem;
	margin: 0 2rem;
}
/* Container for tech stacks and images */
.tech-stack__container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	padding: 5rem;
}
.tech-stack__header {
	color: #787676;
	margin-bottom: 7rem;
}
.tech-stack__p {
	font-family: 'Roboto Mono', monospace;
	font-size: 2.5rem;
	font-weight: 100;
	font-style: italic;
	color: #787676;
}
.tech-stack__img {
	width: 6rem;
	height: auto;
	max-height: 7rem;
	min-height: 4rem;
}
/* GOODBYE */
.goodbye {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
	width: 75%;
	text-align: center;
	margin: 2rem auto;
}
.goodbye__h2 {
	font-family: 'Roboto Mono', monospace;
	font-size: 2.5rem;
	font-weight: 100;
	letter-spacing: .5rem;
	line-height: 3.7rem;
	color: rgb(109, 183, 252);
}
/* FOOTER */
footer {
	padding: 1rem 0;
	margin: 1rem;
}
.footer__comment--wrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 5rem;
}
.footer__comment--img {
	margin-right: 3rem;
	padding: 0;
}
.footer__commented-out {
	font-family: 'Roboto Mono', monospace;
	font-size: 1rem;
	font-weight: 700;
	color: green;
}
.footer__info {
	font-family: 'Roboto Mono', monospace;
	font-size: .9rem;
	font-weight: 300;
	letter-spacing: .1rem;
	line-height: 1.5rem;
	color: #787676;
}
.footer__link--container {
	display: flex;
	flex-direction: column;
}
.footer__link {
	text-decoration: none;
	font-family: 'Roboto Mono', monospace;
	font-size: .9rem;
	color: #787676;
	font-weight: 300;
	letter-spacing: .1rem;
	line-height: 2rem;
	transition: all .2s ease-in-out;
}
.footer__link--container > a:hover {
	cursor: pointer;
	color: #787676;
	transform: scale(1.1);
}
.copyright {
	font-family: 'Roboto Mono', monospace;
	font-size: .7rem;
	font-weight: 200;
	margin-top: 3rem;
}

/* 1425 PX */
@media (max-width: 1425px) {
	.footer__comment--img {
		margin: 0;
		max-width: 3rem;
	}
	.footer__commented-out {
		font-size: .9rem;
	}
}
/* 1200 PX */
@media (max-width: 1200px) {
	.goodbye__h2 {
		font-size: 2.2rem;
	}
	.footer__commented-out {
		font-size: .8rem;
	}
	.footer__info {
		font-size: .7rem;
	}
	.footer__link {
		font-size: .7rem;
	}
}
@media (max-width: 1100px) {
	.footer__commented-out {
		font-size: .7rem;
	}
}
/* 1024 PX */
@media (max-width: 1024px) {
	/* HEADER SECTION */
.header {
	padding: 2.5rem;
	margin-bottom: 2.5rem;
}
header > h1 {
	font-size: 2.5rem;
	margin-bottom: 2.5rem;
}
.type-animation {
	font-size: 2rem;
	color: rgb(109, 183, 252);
	width: 75%;
	margin-bottom: 20vh;
}
/* COLLAPSIBLE INFORMATION SECTION */
.collapse__element--container {
	height: 60vh;
}
.collapse__btn {
	font-size: 2.2rem;
	padding: 2rem;
}
.collapse__btn:hover {
	padding: 2rem;
}
.collapse__btn:focus {
	padding: 2rem;
}
#fade-text > h3 {
	font-size: 2.5rem;
	margin: 3rem;
}
#fade-text > a {
	font-size: 1.2rem;
}
/* PROJECTS SECTION */
.projects__header {
	display: flex;
	justify-content: center;
	align-content: center;
	width: 100%;
	height: auto;
	margin-top: 20vh;
}
.projects__header--h1 {
	color: #787676;
}
h1 {
	font-size: 3rem;
}
.project__wrapper {
	background-color: rgb(208, 239, 239);
	border-radius: .5rem;
	-webkit-box-shadow: 9px 8px 14px 3px rgba(0,0,0,0.28); 
	box-shadow: 9px 8px 14px 3px rgba(0,0,0,0.28);
}
.project__img {
	max-width: 80%;
	max-height: auto;
}
.project__info--header {
	font-size: 2rem;
	margin-bottom: 2rem;
}
.project__info--h5 {
	font-size: 1rem;
	margin-bottom: 2rem;
}
.project__info--arrow {
	height: .7rem;
	width: .7rem;
}
.project__info--ul > li {
	font-size: .8rem;
	margin: 1rem 0;
}
.project__info--stars {
	font-size: 1rem;
	margin: .5rem;
}
.project__info--link-container {
	width: 8rem;
	height: 3.5rem;
	margin-top: 2rem;
	transition: .3s all ease-in-out;
}
.project__info--link-container:hover {
	background-color: #757474;
	transform: scale(1.1);
	text-transform: uppercase;
}
.project__info--link-container > a {
		text-decoration: none;
		color: white;
		transition: .3s all ease-in-out;
		cursor: pointer;
}
.project__info--link-container > a:hover {
	text-decoration: none;
	text-transform: uppercase;
	color: white;
	font-weight: 900;
}
.project__info--link-p {
	font-size: .8rem;
	text-transform: uppercase;
}
/* TECH STACKS */
/* Container for whole section */
.tech-stacks__container {
	padding: 3rem 2rem;
	margin: 0 2rem;
}
.tech-stack__header {
	margin-bottom: 4rem;
}
.tech-stack__p {
	font-size: 2rem;
}
.tech-stack__img {
	width: 5rem;
	height: auto;
	max-height: 6rem;
	min-height: 4rem;
}
/* GOODBYE */
.goodbye {
	min-height: 60vh;
	width: 65%;
	margin: 2rem auto;
}
.goodbye__h2 {
	font-size: 2rem;
	letter-spacing: .5rem;
	line-height: 3rem;
}
/* FOOTER */
.footer__comment--wrapper {
	margin-bottom: 3rem;
}
.footer__comment--img {
	margin: 0;
	max-width: 2rem;
}
.footer__commented-out {
	font-size: .7rem;
}
.footer__info {
	line-height: 1.1rem;
}
.footer__link {
	line-height: 1.2rem;
}
.copyright {
	font-size: .6rem;
}
}

@media (max-width: 765px) {
/* HEADER SECTION */
.header {
	padding: 2.5rem;
}
header > h1 {
	font-size: 2rem;
	text-align: center;
	margin-bottom: 2rem;
}
.type-animation {
	font-size: 1.6rem;
	text-align: center;
	margin-bottom: 15vh;
}
/* COLLAPSIBLE INFORMATION SECTION */
.collapse__element--container {
	height: 40vh;
}
.collapse__btn {
	font-size: 1.2rem;
	text-align: center;
}
#fade-text > h3 {
	font-size: 2rem;
	margin: 3rem;
}
#fade-text > a {
	font-size: .8rem;
}
/* PROJECTS SECTION */
.projects__header {
	margin-top: 15vh;
}
.projects__header--h1 {
	font-size: 2rem;
}
h1 {
	font-size: 3rem;
}
.project__wrapper {
	background-color: rgb(208, 239, 239);
	border-radius: .5rem;
	-webkit-box-shadow: 9px 8px 14px 3px rgba(0,0,0,0.28); 
	box-shadow: 9px 8px 14px 3px rgba(0,0,0,0.28);
}
.project__img {
	max-width: 80%;
	max-height: auto;
}
.project__info--header {
	font-size: 1.2rem;
	margin-bottom: 1rem;
}
.project__info--h5 {
	font-size: .8rem;
	margin-bottom: 1rem;
}
.project__info--arrow {
	height: .7rem;
	width: .7rem;
}
.project__info--ul > li {
	font-size: .7rem;
	margin: 1rem 0;
}
.project__info--stars {
	font-size: .8rem;
	margin: .5rem;
}
.project__info--link-container {
	width: 7rem;
	height: 3rem;
	margin-top: 1.5rem;
}
.project__info--link-p {
	font-size: .7rem;
	text-transform: uppercase;
}
/* TECH STACKS */
/* Container for whole section */
.tech-stacks__container {
	padding: 2rem 1rem;
	margin: 0 1rem;
}
.tech-stack__header {
	font-size: 2rem;
	margin-bottom: 2rem;
}
.tech-stack__p {
	font-size: 1.8rem;
}
.tech-stack__img {
	width: 4rem;
	height: auto;
	color: green;
	min-height: 4rem;
	max-height: 4rem;
}
/* GOODBYE */
.goodbye {
	min-height: 60vh;
	width: 65%;
	margin: 2rem auto;
}
.goodbye__h2 {
	font-size: 1.8rem;
	letter-spacing: .3rem;
	line-height: 2.5rem;
}
/* FOOTER */
.footer__comment--wrapper {
	margin-bottom: 3rem;
}
.footer__comment--img {
	margin: 0;
	max-width: 2rem;
}
.footer__commented-out {
	font-size: .7rem;
}
.footer__link {
	line-height: 1.2rem;
}
.copyright {
	font-size: .6rem;
}

	
}

/* KEYFRAMES ANIMATION */
@keyframes rotate {
	0% {
		transform: scale(1) rotate(0deg);
	}
	25% {
		transform: scale(1.5) rotate(25deg);
	}
	50% {
		transform: scale(2) rotate(45deg);
	}
	75% {
		transform: scale(1.5) rotate(25deg);
	}
	100% {
		transform: scale(1) rotate(0deg);
	}
}
